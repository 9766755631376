import * as React from "react";
import styled from 'styled-components';
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ColoredSeparator from "../components/colored-separator";
import Crotchet from "../components/crotchet";
import Meta from "../components/meta";

const Title = styled.div`
  font-family: 'GLECB','GLECB2';
  font-size: 3rem; 
  text-align:center;
`;

const CGVContainer = styled.div`
  & > h2 {
    font-family: 'GLECB','GLECB2';
    font-size: 1.7rem;
    margin: 0;
  }

  & > h1 {
    font-family: 'GLECB','GLECB2';
    font-size: 2rem;
    margin-top: 48px;
  }

  & > p {
    font-size: 1.2rem;
    line-height: 20px;
    text-align: justify;
    & > a {
      color: black;
      
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 128px;
  padding-right: 128px;

  @media(max-width: 768px){
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const CGV = ({ data: { markdownRemark } }) => {
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Meta title="CGV"/>
      <ColoredSeparator />
      <Container>
        <Title>{frontmatter.title}</Title>
        <Title>CGV</Title>
        <CGVContainer dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
      <Crotchet />
    </Layout>
  );
}

export default CGV;

export const query = graphql`
  {
    markdownRemark(frontmatter: {id: {eq: "CGV"}}) {
      id
      html
      frontmatter {
        slug
        title
        date
        id
      }
    }
  }
`
